import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { AlertService } from '../services/alert-service.service';
import { ModalComponent } from '../modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from '../services/form-change.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: any = {};
  returnUrl = '/home';
  loginForm;
  constructor(public authenticationService: AuthenticationService,
    public alertService: AlertService,
    private modalService: NgbModal,
    public authenSer: AuthenticationService,
    private formService: FormChangeService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) {

    }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: new FormControl('',[Validators.required]),
      password: new FormControl('',[Validators.required]),
    });
    if (this.authenticationService.isAuthenticated()) {
      this.router.navigate(['/home']);
    }
  }
  login() {

    this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password).subscribe(
      (data) => {
        localStorage.setItem("jwttoken", JSON.stringify(data["jwttoken"]));
        debugger;
        this.authenticationService.getUserDevArchiProducts().subscribe(
          (data)=>{

            let lstApp = data as Array<any>;
            let userApps = lstApp.map(el=>{
              return { text: el['name'], path: el['name'], appId: el['id'] };
            });
            localStorage.setItem("userApps", JSON.stringify(userApps));
            this.router.navigate(["home"]);
          }
        );

      },
      (error) => {
        let msg = "L'identifiant ou le mot de passe est incorrect";
        console.log(JSON.stringify(error));
        this.alertService.error(msg);
      }
    );


  }
}
