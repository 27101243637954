export class Alert {
    type: AlertType;
    message: string;
    keepAfterRouteChange: boolean;
    fade: boolean;

}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}
