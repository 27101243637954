import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { TenantService } from '../services/tenant.service';
import { FormChangeService } from '../services/form-change.service';

@Component({
  selector: 'app-mon-profile',
  templateUrl: './mon-profile.component.html',
  styleUrls: ['./mon-profile.component.scss']
})
export class MonProfileComponent implements OnInit {
    mytenantForm: FormGroup;

    selectedSite: string = null;
    selectedCustomer: string = null;
    selectedCivilite: string = null;
    employeeToSave: any;
    emailInvalid: boolean;
    numSsInvalid: boolean;
    valeurAcorriger = [];
    cpInvalid: boolean;
    lstSites = [];
    lstCustomers =  [];
    consultationMode= true;
    // typeContratEnum = typeContrat;
    // keys = Object.keys;
    genres = [
      {id: 1, code:'MADAME', name:'Madame'},
      {id: 2, code:'MADEMOISELLE', name:'Mademoiselle'},
      {id: 3, code:'MONSIEUR', name: 'Monsieur'}
    ];
    myTenant: any;
    constructor(
      private fb: FormBuilder,
      protected alertService: AlertService,
      private authenSer: AuthenticationService,
      public activeModal: NgbActiveModal,
      private formService: FormChangeService,
      private route: Router,
      private tenantServ: TenantService
    ) {}

    ngOnInit(): void {
      this.initForm();
      if (this.authenSer.isAdmin) {
        this.loadStripe();
        this.tenantServ.getTenants().subscribe(
          data =>{
            this.myTenant = data;

            this.updateForm(data);
          }
        )
      }
    }

    loadStripe() {
      if(!window.document.getElementById('stripe-script')) {
        var s = window.document.createElement("script");
        s.id = "stripe-script";
        s.type = "text/javascript";
        s.src = "https://checkout.stripe.com/checkout.js";
        window.document.body.appendChild(s);
      }
  }
  pay(amount) {
    var handler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_aeUUjYYcx4XNfKVW60pmHTtI',
      locale: 'auto',
      mode: 'setup',
      livemode: false,
      token: function (token: any) {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        console.log(token)

        alert('Token Created!!');
      }
    });
    handler.open({
        name: 'Moyen de Payement',
        description: 'Carte de Payement utilsée',
        amount: amount * 100
      });
  }

    initForm() {
      this.mytenantForm = this.fb.group({
        id: new FormControl(""),
        remindMailActive: new FormControl(""),
        remindMailDay: new FormControl(""),
        remindSmsActive: new FormControl(""),
        remindSmsDay: new FormControl(""),
        systemPaie: new FormControl(""),
        systemPaieBddHost: new FormControl(""),
        systemPaieBddPwd: new FormControl(""),
        systemPaieBddUser: new FormControl(""),
        systemPaieVersion: new FormControl(""),
        systemSync: new FormControl(""),

        activity: new FormControl(""),
        adress: new FormControl(""),
        city: new FormControl(""),
        companyName: new FormControl(""),
        contactEmail: new FormControl(""),
        contactName: new FormControl(""),
        contactPosition: new FormControl(""),
        cp: new FormControl(""),
        methodPaymentId: new FormControl(""),
        siret: new FormControl(""),
        stripeCustomerId: new FormControl(""),
        tel1: new FormControl(""),
        tel2: new FormControl(""),
      });

    }

    updateForm(tenant): void {
      this.mytenantForm.patchValue({
        id: this.myTenant.id,
        remindMailActive: tenant.remindMailActive,
        remindMailDay: tenant.remindMailDay,
        remindSmsActive: tenant.remindSmsActive,
        remindSmsDay: tenant.remindSmsDay,
        systemPaie: tenant.systemPaie,
        systemPaieBddHost: tenant.systemPaieBddHost,
        systemPaieBddPwd: tenant.systemPaieBddPwd,
        systemPaieBddUser: tenant.systemPaieBddUser,
        systemPaieVersion: tenant.systemPaieVersion,
        systemSync: tenant.systemSync,

        activity: tenant.activity,
        adress: tenant.adress,
        city: tenant.city,
        companyName: tenant.companyName,
        contactEmail: tenant.contactEmail,
        contactName: tenant.contactName,
        contactPosition: tenant.contactPosition,
        cp: tenant.cp,
        methodPaymentId: tenant.methodPaymentId,
        siret: tenant.siret,
        stripeCustomerId: tenant.stripeCustomerId,
        tel1: tenant.tel1,
        tel2: tenant.tel2,
      });
    }

    private createFromForm(): any {
      return {
        id: this.myTenant.id,
        remindMailActive: this.mytenantForm.get(["remindMailActive"])!.value,
        remindMailDay: this.mytenantForm.get(["remindMailDay"])!.value,
        remindSmsActive: this.mytenantForm.get(["remindSmsActive"])!.value,
        remindSmsDay: this.mytenantForm.get(["remindSmsDay"])!.value,
        systemPaie: this.mytenantForm.get(["systemPaie"])!.value,
        systemPaieBddHost: this.mytenantForm.get(["systemPaieBddHost"])!.value,
        systemPaieBddPwd: this.mytenantForm.get(["systemPaieBddPwd"])!.value,
        systemPaieBddUser: this.mytenantForm.get(["systemPaieBddUser"])!.value,
        systemPaieVersion: this.mytenantForm.get(["systemPaieVersion"])!.value,
        systemSync: this.mytenantForm.get(["systemSync"])!.value,

        activity: this.myTenant.activity,
        adress: this.mytenantForm.get(["adress"])!.value,
        city: this.mytenantForm.get(["city"])!.value,
        companyName: this.myTenant.companyName,
        contactEmail: this.mytenantForm.get(["contactEmail"])!.value,
        contactName: this.myTenant.contactName,
        contactPosition: this.myTenant.contactPosition,
        cp: this.mytenantForm.get(["cp"])!.value,
        methodPaymentId: this.myTenant.methodPaymentId,
        siret: this.myTenant.siret,
        stripeCustomerId: this.myTenant.stripeCustomerId,
        tel1: this.mytenantForm.get(["tel1"])!.value,
        tel2: this.myTenant.tel2,
      };
    }

    isValid(f) {
      let val =f.form.valid;
      return val;
    }
    validate(){
      const tenentUpdated= this.createFromForm();
      this.tenantServ.putTenant(tenentUpdated).subscribe(
        (data)=>{
          this.alertService.success("Modification Sauvegardé !", true);
          this.myTenant = data;
          this.updateForm(data);

          this.switchMode();
        },
        (error)=>{
          this.alertService.error("Erreur lors de la sauvgarde !", true);

        }
      );


    }
    switchMode(){
      this.consultationMode = !this.consultationMode;
    }
    annuler(){
      this.consultationMode = true;
    }
  }
