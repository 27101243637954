import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-digicod',
  templateUrl: './manage-digicod.component.html',
  styleUrls: ['./manage-digicod.component.scss']
})
export class ManageDigicodComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
