import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Before the component
declare const Stripe: any;
@Component({
  selector: 'app-add-card-modal',
  templateUrl: './add-card-modal.component.html',
  styleUrls: ['./add-card-modal.component.scss']
})
export class AddCardModalComponent implements OnInit {
  stripe;
  card;
  loading = false;
  cardAdded = false;
  cardInfos: any;
  customer: any;
  constructor(private fb: FormBuilder, private creditCardServ: CreditCardService, private activeModal: NgbActiveModal) {

   }

   loadStripe() {

    if (!window.document.getElementById('stripe-custom-form-script')) {
      const s = window.document.createElement('script');
      s.id = 'stripe-custom-form-script';
      s.type = 'text/javascript';
      s.async = false;
      s.charset = 'utf-8';
      s.src = 'https://js.stripe.com/v3/';
      s.onload = this.initStripeElements();
      document.getElementsByTagName('head')[0].appendChild(s);
    }
  }
  initStripeElements(): (this: GlobalEventHandlers, ev: Event) => any {
    this.creditCardServ.getPublicKey()
      .subscribe(
        (data)=>{
          let publicKey = data['publicKey'];
          this.stripeElements(publicKey);
        },
        (error)=>{
        });
        return;
  }

  stripeElements(publicKey) {

    this.stripe = Stripe(publicKey);
    var elements = this.stripe.elements();

    // Element styles
    let style = {
      base: {
        fontSize: '16px',
        color: '#32325d',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: 'rgba(0,0,0,0.4)'
        }
      }
    };

    this.card = elements.create('card', {hidePostalCode: true, style: style });
    this.card.mount('#card-element');
    this.card.on('focus', function() {
      var el = document.getElementById('card-element');
      el.classList.add('focused');
    });

    this.card.on('blur', function() {
      var el = document.getElementById('card-element');
      el.classList.remove('focused');
    });
    }


  changeLoadingState(isLoading) {
    document.querySelector('#spinner').classList.toggle('hidden');
    this.loading = isLoading;
  }
   showCardError(error) {
    //
    this.changeLoadingState(false);
    // The card was declined (i.e. insufficient funds, card has expired, etc)
    var errorMsg = document.querySelector('.sr-field-error');
    errorMsg.textContent = error.message;
    setTimeout(function() {
      errorMsg.textContent = '';
    }, 8000);
  }

  orderComplete(subscription) {

    this.changeLoadingState(false);
    this.cardAdded = true;
    this.cardInfos = subscription;
  }

  registerCard(paymentMethod) {
    this.creditCardServ.registerCard(paymentMethod).subscribe(
      (data)=>{
        this.orderComplete(data);
      },
      (error)=>{

      }
    )
  }
  ProcessCreateCardResault(result){

    if (result.error) {
      this.showCardError(result.error);
    } else {
      this.registerCard(result.paymentMethod.id);
    }
  }
  pay() {

    this.changeLoadingState(true);
    const elements = this.stripe.elements();
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        name: this.customer.contactName,
        email: this.customer.contactEmail,
      },
    })
    .then((result)=>this.ProcessCreateCardResault(result));
  }

  close( data){
    this.activeModal.close(data);
  }
  ngOnInit() {
    // this.loadStripe();
    this.initStripeElements();
  }

}
