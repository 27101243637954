import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { Role } from '../class/role';
import { User } from '../class/user';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
const apiUrl = environment.serverUrl + 'api/';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }

  saveProspects(prospect): Observable<any> {

    const url = apiUrl + this.authenSrv.getUserApiTargetUrl() +'/prospects';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(url, prospect, { withCredentials: true });
  }

  putProspects(prospect): Observable<any> {
      const url = apiUrl + this.authenSrv.getUserApiTargetUrl() + '/prospects';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.put(url, prospect, { withCredentials: true });
  }

  deleteProspects(id: any) {
    const url = apiUrl + this.authenSrv.getUserApiTargetUrl() + '/prospects/' + id;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(url, { withCredentials: true });
  }

  getProspects(appName) {// /api/user/prospects/product/{product}

    let subUrl = this.authenSrv.getUserApiTargetUrl() + '/prospects';
    if(appName){
      subUrl += '/product/' + appName;
    }
    const url = apiUrl + subUrl;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { withCredentials: true });
  }
}
