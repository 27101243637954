import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Role } from '../class/role';
import { User } from '../class/user';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const apiUrl = environment.serverUrl + 'api/';


@Injectable({
    providedIn: 'root'
})
export class UsersService {

    deleteUser(id: any) {
        let subUrl = this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/users/' + id;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.delete(url, { withCredentials: true });

    }
    getRoles() {
      let subUrl =this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/roles';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }
    getApps() {
      let subUrl =this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/products';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }
    saveUser(user): Observable<any> {
      let subUrl = this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/users';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, user, { withCredentials: true });
    }

    getUser() {
        let subUrl =this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/users';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }

    putUser(user: any): Observable<any> {
        // let subUrl = Role.valueOf(currentUser.role).value;
        let subUrl = this.authenSer.getUserApiTargetUrl();
        let url = apiUrl + subUrl + '/users';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(url, user, { withCredentials: true });
    }

    acceptCvg(): Observable<any> {
      let subUrl =this.authenSer.getUserApiTargetUrl();
      const url = apiUrl + subUrl + '/users/acceptContract';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.post(url, null, { headers, withCredentials: true });
    }
    constructor(private http: HttpClient, private authenSer: AuthenticationService) { }
}
