import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormChangeService {

  formEmployeeSearchChanged: EventEmitter<Array<any>>;
  formEmployeeSearchRequest: EventEmitter<boolean>;
  formMinUserSearchEvent: EventEmitter<Array<any>>;
  formAllEmployeeChanged: EventEmitter<Array<any>>;

  formSubmissionSearchChanged: EventEmitter<Array<any>>;

  formCustomerSearchChanged: EventEmitter<Array<any>>;

  reloadSearchRequest: EventEmitter<boolean>;
  userMessagesChanged: EventEmitter<Array<any>>;
  //isolé
  formProspectSearchChanged: EventEmitter<Array<any>>;
  reloadProspectRequest: EventEmitter<boolean>;

  formProductSearchChanged: EventEmitter<Array<any>>;
  reloadProductRequest: EventEmitter<boolean>;

  formDigicodsSearchChanged = new EventEmitter<Array<any>>();
  reloadDigicodsRequest = new EventEmitter<boolean>();
  userAps = new EventEmitter<Array<any>>();
  //isolé
    reloadTenantSearchRequest: EventEmitter<boolean>;
    formTenantListChanged: EventEmitter<Array<any>>;
  //isolé
  reloadVariablePaieSearchRequest: EventEmitter<boolean>;
  formVariablePaieSearchChanged: EventEmitter<Array<any>>;
  //isolé
  reloadSaisiesRequest: EventEmitter<boolean>;
  formSaisiesSearchChanged: EventEmitter<Array<any>>;
  formSaisiesListChanged: EventEmitter<Array<any>>;

  reloadAddDataPaieRequest: EventEmitter<boolean>;
  formAddDataPaieListChanged: EventEmitter<Array<any>>;
  //isolé
  reloadAbsencesEvent: EventEmitter<boolean>;
  formAbsencesListChanged: EventEmitter<Array<any>>;
    //isolé
  reloadCustomerEvent: EventEmitter<boolean>;
  reloadLoginStatusEvent: EventEmitter<boolean>;
  reloadSearchRequestUser: EventEmitter<boolean>;
  reloadSearchCustomer: EventEmitter<boolean>;
  periodSelectChanged: EventEmitter<any>;
  formEmployeeAbsCreate: EventEmitter<any>;
  constructor() {
    this.formEmployeeSearchChanged = new EventEmitter<Array<any>>();
    this.formEmployeeSearchRequest = new EventEmitter<boolean>();
    this.formMinUserSearchEvent = new EventEmitter<Array<any>>();
    this.formAllEmployeeChanged = new EventEmitter<Array<any>>();
    this.reloadAddDataPaieRequest = new EventEmitter<boolean>();
    this.formAddDataPaieListChanged = new EventEmitter<Array<any>>();
    this.formSubmissionSearchChanged = new EventEmitter<Array<any>>();
    this.formCustomerSearchChanged = new EventEmitter<Array<any>>();
    this.formSaisiesSearchChanged = new EventEmitter<Array<any>>();
    this.formSaisiesListChanged = new EventEmitter<Array<any>>();
    this.formVariablePaieSearchChanged = new EventEmitter<Array<any>>();
    this.reloadVariablePaieSearchRequest = new EventEmitter<boolean>();
    this.reloadTenantSearchRequest = new EventEmitter<boolean>();
    this.formTenantListChanged = new EventEmitter<Array<any>>();
    this.formProspectSearchChanged = new EventEmitter<Array<any>>();
    this.reloadProspectRequest = new EventEmitter<boolean>();
    this.formProductSearchChanged = new EventEmitter<Array<any>>();
    this.reloadProductRequest = new EventEmitter<boolean>();
    this.reloadSearchRequest = new EventEmitter<boolean>();
    this.userMessagesChanged = new EventEmitter<Array<any>>();
    this.reloadSaisiesRequest = new EventEmitter<boolean>();
    this.reloadAbsencesEvent = new EventEmitter<boolean>();
    this.formAbsencesListChanged = new EventEmitter<Array<any>>();
    this.reloadSearchRequestUser = new EventEmitter<boolean>();
    this.reloadSearchCustomer = new EventEmitter<boolean>();
    this.periodSelectChanged = new EventEmitter<any>();
    this.formEmployeeAbsCreate = new EventEmitter<Array<any>>();
    this.reloadCustomerEvent = new EventEmitter<boolean>();
    this.reloadLoginStatusEvent = new EventEmitter<boolean>();
    this.userAps = new EventEmitter<Array<any>>();
  }
  raiseuserApsEvent(liste){
    this.userAps.emit(liste);
  }

  raiseEmployeeAbsForm(liste) {
    this.formEmployeeAbsCreate.emit(liste);
  }

  raiseEmployeeListEvent(liste): void {
    this.formEmployeeSearchChanged.emit(liste);
  }

  raiseFormEmployeeSearchRequest() {
    this.formEmployeeSearchRequest.emit(true);
  }

  raiseFormMinUserSearch(liste): void {
    this.formMinUserSearchEvent.emit(liste);
  }
  raiseAllEmployeeListEvent(liste): void {
    this.formAllEmployeeChanged.emit(liste);
  }

  raiseSubmissionListEvent(liste): void {
    this.formSubmissionSearchChanged.emit(liste);
  }
  raiseVariablePaieListEvent(liste): void {
    this.formVariablePaieSearchChanged.emit(liste);
  }
  reloadVariablePaieSearchRequestEvent(): void {
    this.reloadVariablePaieSearchRequest.emit(true);
  }

  raiseProspectListEvent(liste): void {
    this.formProspectSearchChanged.emit(liste);
  }
  raiseReloadProspectSearchRequestEvent(): void {
    this.reloadProductRequest.emit(true);
  }
  raiseProductListEvent(liste): void {
    this.formProductSearchChanged.emit(liste);
  }
  raiseProductSearchRequestEvent(): void {
    this.reloadProspectRequest.emit(true);
  }

  raiseDigicodsListEvent(liste): void {
    this.formDigicodsSearchChanged.emit(liste);
  }
  raiseReloadDigicodsSearchRequestEvent(): void {
    this.reloadDigicodsRequest.emit(true);
  }
  raiseUserMessagesListEvent(liste): void {
    this.userMessagesChanged.emit(liste);
  }
  raiseCustomerListEvent(liste): void {
    this.formCustomerSearchChanged.emit(liste);
  }
  raiseSaisiesSearchChangedEvent(liste): void {
    this.formSaisiesSearchChanged.emit(liste);
  }

  raiseSaisiesListEvent(liste): void {
    this.formSaisiesListChanged.emit(liste);
  }
  raiseReloadSaisiesRequestEvent(): void {
    this.reloadSaisiesRequest.emit(true);
  }
  raiseTenantsListEvent(liste): void {
    this.formTenantListChanged.emit(liste);
  }
  raiseReloadTenantsSearchRequest(): void {
    this.reloadTenantSearchRequest.emit(true);
  }
  raiseAddDataPaieListEvent(liste) {
    this.formAddDataPaieListChanged.emit(liste);
  }
  raiseReloadAddDataPaieRequestEvent(): void {
    this.reloadAddDataPaieRequest.emit(true);
  }
  raisereloadSearchRequestEvent(): void {
    this.reloadSearchRequest.emit(true);
  }

  raisereReloadAbsencesEvent(): void {
    this.reloadAbsencesEvent.emit(true);
  }
  raiseAbsencesListEvent(liste): void {
    this.formAbsencesListChanged.emit(liste);
  }

  raisereReloadCustomerEvent(): void {
    this.reloadCustomerEvent.emit(true);
  }
  raisereReloadLoginStatusEvent(): void {
    this.reloadLoginStatusEvent.emit(true);
  }

  raisereloadSearchCustomerEvent(): void {
    this.reloadSearchCustomer.emit(true);
  }
  raisereloadSearchRequestUserEvent(): void {
    this.reloadSearchRequestUser.emit(true);
  }


  raiserePeriodSelectChangedEvent(selectedPeriod): void {
    this.periodSelectChanged.emit(selectedPeriod);
  }

}
