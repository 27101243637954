import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { MaterialModule } from './material/material.module';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NotifierModule } from 'angular-notifier';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import 'moment/locale/fr';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { ModalComponent } from './modal/modal.component';
import { ModalRASComponent } from './modal/modalRAS.component';
import { AuthGuard } from './Guard/AuthGuard';
import { AlertService } from './services/alert-service.service';
import { AuthenticationService } from './services/authentication.service';
import { AlertComponent } from './alert/alert.component';
import { ShContextMenuModule } from 'ng2-right-click-menu'
import { MomentPipe } from './moment-pipe.pipe';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { RessetLoginComponent } from './resset-login/resset-login.component';
import { PrettyprintPipe } from './prettyprint.pipe';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ProspectCreateComponent } from './prospects/prospect-create/prospect-create.component';
import { ProspectUpdateComponent } from './prospects/prospect-update/prospect-update.component';
import { FormMinProspectComponent } from './prospects/form-min-prospect/form-min-prospect.component';
import { MonProfileComponent } from './mon-profile/mon-profile.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { RangesFooter } from './prospects/form-min-prospect/ranges-footer/ranges-footer.component';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { DigicodsComponent } from './digicods/digicods.component';
import { FormMinDigicodsComponent } from './digicods/form-min-digicods/form-min-digicods.component';
import { ManageDigicodComponent } from './digicods/manage-digicod/manage-digicod.component';
import { NotifsDigicodComponent } from './digicods/notifs-digicod/notifs-digicod.component';
import { LogsDigicodComponent } from './digicods/logs-digicod/logs-digicod.component';
import { UsersComponent } from './users/users.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserUpdateComponent } from './users/user-update/user-update.component';
import { FormMinUserComponent } from './form-min-user/form-min-user.component';
import { ProductsComponent } from './products/products.component';
import { FormMinProductsComponent } from './form-min-products/form-min-products.component';
import { ProductUpdateComponent } from './product-update/product-update.component';
import { ProductCreateComponent } from './product-create/product-create.component';
import { TenantsComponent } from './tenants/tenants.component';
import { CreateTenantComponent } from './tenants/create-tenant/create-tenant.component';
import { UpdateTentantComponent } from './tenants/update-tentant/update-tentant.component';
import { FormMinTenantComponent } from './tenants/form-min-tenant/form-min-tenant.component';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { AddCardModalComponent } from './credit-card-form/add-card-modal/add-card-modal.component';
import { AddSepaModalComponent } from './credit-card-form/add-sepa-modal/add-sepa-modal.component';
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoginComponent,
    HomeComponent,
    FooterComponent,
    ModalComponent,
    AlertComponent,
    MomentPipe,
    RessetLoginComponent,
    PrettyprintPipe,
    ChangePassComponent,
    ProspectsComponent,
    ProspectCreateComponent,
    ProspectUpdateComponent,
    FormMinProspectComponent,
    MonProfileComponent,
    AddSepaModalComponent,
    ModalRASComponent,
    RangesFooter,
    DigicodsComponent,
    FormMinDigicodsComponent,
    ManageDigicodComponent,
    NotifsDigicodComponent,
    LogsDigicodComponent,
    UsersComponent,
    UserCreateComponent,
    UserUpdateComponent,
    TenantsComponent,
    CreditCardFormComponent,
    AddCardModalComponent,
    CreateTenantComponent,
    UpdateTentantComponent,
    FormMinTenantComponent,
    FormMinUserComponent,
    ProductsComponent,
    FormMinProductsComponent,
    ProductUpdateComponent,
    ProductCreateComponent,
  ],
  imports: [
    MaterialModule,
    AppRoutingModule,
    BrowserModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NotifierModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    NgbModule,
    ShContextMenuModule,
    ChartsModule,
    CommonModule,
    SatDatepickerModule, SatNativeDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    NgbActiveModal,
    AuthGuard,
    AlertService,
    AuthenticationService,
    MomentPipe, PrettyprintPipe,SatDatepickerModule, SatNativeDateModule,
    {
      provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptorService,
        multi: true
    },
    [{ provide: LOCALE_ID, useValue: 'fr-FR' },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'}],
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalComponent,
    ModalRASComponent,
    RangesFooter,
    ProspectsComponent,
    LoginComponent,
    ProductUpdateComponent,
    ProductCreateComponent,
    AddSepaModalComponent,
  ]
})
export class AppModule { }
