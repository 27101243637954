import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Role } from '../class/role';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const apiUrl = environment.serverUrl + 'api/';
@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  getCustomerCard() {
    const url = apiUrl + 'admin/payments/card';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { withCredentials: true });
  }
  getCusstomerPaymentMethods(){
    const url = apiUrl + 'admin/payments/paymentMethods';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { withCredentials: true });
  }
  getCustomerIBAN() {
    const url = apiUrl + 'admin/payments/iban';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { withCredentials: true });
  }
  constructor(private http: HttpClient) { }

  getPublicKey() {
    const url = apiUrl + 'admin/payments/publicKeyJson';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { withCredentials: true });
  }
  registerCard(paymentMethod){
    const formData: FormData = new FormData();
    formData.append('paymentMethodId', paymentMethod);
    let url = apiUrl + 'admin/payments/registerCard';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });
  }
  registerIban(id){
    const formData: FormData = new FormData();
    formData.append('sourceId', id);
    let url = apiUrl + 'admin/payments/registerIban';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });
  }
}
