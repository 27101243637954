import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './Guard/AuthGuard';
import { Role } from './class/role';
import { RessetLoginComponent } from './resset-login/resset-login.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ProspectCreateComponent } from './prospects/prospect-create/prospect-create.component';
import { ProspectUpdateComponent } from './prospects/prospect-update/prospect-update.component';
import { UsersComponent } from './users/users.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserUpdateComponent } from './users/user-update/user-update.component';
import { MonProfileComponent } from './mon-profile/mon-profile.component';
import { ProductsComponent } from './products/products.component';
import { TenantsComponent } from './tenants/tenants.component';
import { CreateTenantComponent } from './tenants/create-tenant/create-tenant.component';
import { UpdateTentantComponent } from './tenants/update-tentant/update-tentant.component';
import { AddCardModalComponent } from './credit-card-form/add-card-modal/add-card-modal.component';

const routes: Routes = [
  {
    path: 'home', component: HomeComponent,
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent },

  {
    path: 'profil', component: MonProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tenants', component: TenantsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SupperAdmin.toString()] }
  },
  {
    path: 'create-tenant', component: CreateTenantComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SupperAdmin.toString()] }
  },
  {
    path: 'update-tenant', component: UpdateTentantComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SupperAdmin.toString()] }
  },

  {
    path: 'users', component: UsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-user', component: UserCreateComponent,
    canActivate: [AuthGuard],
    data: {product : null}

  },
  {
    path: 'update-user', component: UserUpdateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'prospects/:product', component: ProspectsComponent,
    canActivate: [AuthGuard],
    children:[
          {path: '', component: ProspectsComponent},
          {
            path: 'create-prospect', component: ProspectCreateComponent,
            canActivate: [AuthGuard],

          },
          {
            path: 'update-prospect', component: ProspectUpdateComponent,
            data: {prospect : null},
            canActivate: [AuthGuard],
          },
        ]
  },
  {
    path: 'products', component: ProductsComponent,
    canActivate: [AuthGuard],
    data:{ roles: [Role.Admin.toString(), Role.SupperAdmin.toString()]}
  },
  {
    path: 'add-card', component: AddCardModalComponent,
    canActivate: [AuthGuard]
  },
  { path: 'propspects', component: ProspectsComponent },
  {
    path: 'loginResset', component: RessetLoginComponent
  },
  {
    path: 'loginChange', component: ChangePassComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', component: LoginComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [ RouterModule.forRoot(routes, { enableTracing: true }) ],

})
export class AppRoutingModule {
  // constructor(private router: Router) {
    // this.router.config.push(routes);
  //   this.router.config.unshift(
  // {
  //   path: 'login', component: LoginComponent,
  // },
  // {
  //   path: 'prospects/:product', component: ProspectsComponent,
  //   canActivate: [AuthGuard],
  //   children:[
  //     {path: '', component: ProspectsComponent},
  //     {
  //       path: 'create-prospect', component: ProspectCreateComponent,
  //       canActivate: [AuthGuard],
  //       //data: {product : null}

  //     },
  //     {
  //       path: 'update-prospect', component: ProspectUpdateComponent,
  //       canActivate: [AuthGuard],
  //     },
  //   ]
  // },
  //   );
  // }
}
