import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentPipe'
})
export class MomentPipe implements PipeTransform {

  public transform(date, format) {
    if (date && moment(date).isValid()){
      let tmp = moment(date).format(format);

      return tmp;
    } else {
      return '';
    }
  }
  /**
   * format une period de format MMYY en MMMM YYYY
   */
   formatPeriodForDisplay(period: string){
    let mois = Number(period.substring(0,2));
            let annee = 2000 + Number(period.substring(2));
            return moment().day(1)
            .month(mois-1)
            .years(annee)
            .format('MMMM YYYY');
  }

}
