import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import "rxjs/add/operator/map";
import "rxjs/Observable";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";
import { AlertService } from "./alert-service.service";
import { Role } from "../class/role";
import { FormChangeService } from './form-change.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  resetPassword(email: any) {
    let url = environment.serverUrl + "api/users/resetPwd";
    url += "?login=" + email;
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post(url, null);
  }
  changePassword(oldPassword: any, newPassword: any) {
    let url = environment.serverUrl + "api/users/changePwd";
    url +=
      "?oldPassword=" +
      oldPassword +
      "&newPassword=" +
      newPassword +
      "&passwordConfirm=" +
      newPassword;
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post(url, null, { headers, withCredentials: true });
  }
  getUserLoggedIn() {
    return this.getCurrentUser();
  }
  jwtHelper: JwtHelperService = new JwtHelperService();
  public isAuthenticated(): boolean {
    const token = this.getToken();
    let tmp = !!token && !this.isTokenExpired();
    return tmp;
  }
  isTokenExpired(): boolean {
    const token = this.getToken();
    return this.jwtHelper.isTokenExpired(token);
  }
  getToken() {
    return JSON.parse(localStorage.getItem("jwttoken"));
  }
  removeToken() {
    localStorage.removeItem("jwttoken");
  }
  redirectUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService,
    private formServ: FormChangeService,
  ) {}
  login(username: string, password: string) {
    let url = environment.serverUrl + "login";
    let formData = {};
    if (password && username) {
      formData["username"] = username;
      formData["password"] = password;
    }

    let headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post(url, formData, { headers, withCredentials: true })
  }
  getUserDevArchiProducts() {

    let subUrl = this.getUserApiTargetUrl();
    let url =environment.serverUrl +'api/'+ subUrl+ '/products';
    let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.get(url, { withCredentials: true });
  }
  logout() {
    // remove user from local storage to log user out
        localStorage.removeItem("jwttoken");
        localStorage.removeItem('userApps');
        this.router.navigate(["/login"]);
  }

  getCurrentUser() {
    let user;

    let token = localStorage.getItem("jwttoken");
    if (!this.jwtHelper.isTokenExpired(token)) {
      let decoToken = this.jwtHelper.decodeToken(token);
      user = decoToken["user"];
    }
    return user;
  }
  get userApps(){
    let userApps : [];

    if (this.getCurrentUser()) {
       userApps = JSON.parse(localStorage.getItem("userApps"));
    }
    return userApps;
  }
  pullUserApp(appId){
    let apps = this.userApps;
    if(apps.length > 0){
      let newVals = apps.filter(el =>{
        return el['id'] != appId;
      });
      localStorage.setItem("userApps",JSON.stringify(newVals));
    }
  }
  get isAdmin() {
    let user = this.getUserLoggedIn();

    return user && user.roles.includes(Role.Admin.toString());
  }
  get UserHasAdminPrevilege() {
    return this.isAdmin || this.isSupperAdmin;
  }
  get isSale() {
    let user = this.getUserLoggedIn();
    return user && user.roles.includes(Role.Sale.toString());
  }
  get isUser() {
    let user = this.getUserLoggedIn();

    return user && user.roles.includes(Role.User.toString());
  }
  get isSupperAdmin() {
    let user = this.getUserLoggedIn();
    return user && user.roles.includes(Role.SupperAdmin.toString());
  }

  getUserApiTargetUrl() {

    let subUrl;
    if (this.isUser) {
      subUrl = "user";
    }
    if (this.isSale) {
      subUrl = "sale";
    }
    if (this.isAdmin) {
      subUrl = "admin";
    }
    if (this.isSupperAdmin) {
      subUrl = "superadmin";
    }
    return subUrl;
  }
  userHasRole(role: string) {
    let user = this.getCurrentUser();
    if (user) {
      let userroles = user.roles as Array<any>;
      if (userroles && userroles.indexOf(role) === -1) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
