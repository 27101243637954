import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"]
})
export class ModalComponent implements OnInit {
  @Input() title = `Information`;
  @Input() msg: any;
  result: string;
  constructor(public activeModal: NgbActiveModal) {}

  validate() {
    this.activeModal.close(this.msg.id);
  }
  ngOnInit() {}
}
