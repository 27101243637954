import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormChangeService } from '../services/form-change.service';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ProspectService } from '../services/prospect.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { prospectStatus, soncas, DevArchiProducts } from '../class/unit.enum';

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProspectsComponent implements OnInit, OnDestroy {
  constructor(private formService: FormChangeService,
    private authenSer: AuthenticationService,
    private alertService: AlertService,
    private prospectServ: ProspectService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private activeRouter:ActivatedRoute
  ) {

  }
  ngOnDestroy(): void {
  }
  product: any;
  productId: any;
  searchText;
  prospectStatusEnum = prospectStatus;
  soncasEnum = soncas;
  lstProspects = new MatTableDataSource(new Array());
  displayedColumns = ['expandIcon','firstContact','soncas','status','companyName','contactMail','contactTel','nextContact','Actions'
  ];
  innerDisplayedColumns = ['lastContact','modeLastContact','adress','note'];
  expandedElement: {} | null;

  // dataSource =
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  keys = Object.keys;
  ProductsEmun = DevArchiProducts;
  getKeys(object): string[] {
    return Object.keys(object);
  }

  ngOnInit() {

    this.product = this.route.snapshot.params['product'];
    this.productId = history.state.productId;
    this.route.params.subscribe(params => {
      this.productId = history.state.productId;
      this.product = params['product'];
      this.subscribeformChange();
  });
    this.lstProspects.sort = this.sort;
    let _activeChild = this.activeRouter.children.length;
    if (_activeChild!=0) {
        //your active children 1 or more than children then active 1,otherwise it is 0
    }
  }
  subscribeformChange() {
    this.formService.formProspectSearchChanged.subscribe(
      (param) => {

        this.lstProspects = new MatTableDataSource(param);

      });

  }

  create(): void {

  this.router.navigate(['/prospects/' + this.product + '/create-prospect'],{ state: {productId: this.productId}} );
  }
  edit(prospect) {
    this.router.navigateByUrl('/prospects/' + this.product + '/update-prospect', {state: { prospect : prospect, productId: this.productId} });
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un prospect";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }
  delete(id) {
    this.prospectServ.deleteProspects(id).subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );

  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadProspectSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }

  onChange() {
    this.formService.raiseReloadProspectSearchRequestEvent();
  }
}
