import { Component, OnInit, ViewChild } from '@angular/core';
import { InstallationService } from '../services/installation.service';
import { AlertService } from '../services/alert-service.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from '../services/form-change.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-digicods',
  templateUrl: './digicods.component.html',
  styleUrls: ['./digicods.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DigicodsComponent implements OnInit {

  constructor(public installationService: InstallationService,
    private formService: FormChangeService,
    private modalService: NgbModal,
    public alertService: AlertService,
    private router: Router) { }
    rowData: any;
  rowDataFiltred;
  gridOptions : any;
  columnDefs : any;

  lstDigics = new MatTableDataSource(new Array());
  displayedColumns = ['label','dateLastConnection','Actions'
  ];
  innerDisplayedColumns = ['dateModification','dateCreation','gatewayId','desc',];
  expandedElement: {} | null;

  // dataSource =
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  getKeys(object): string[] {
    return Object.keys(object);
  }
  edit(digicod){

  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un prospect";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }

  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadProspectSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }
  ngOnInit() {
    this.subscribeformChange();
    this.lstDigics.sort = this.sort;
    // this.installationService.getInstallationsFromBack().subscribe(
    //   data => {
    //     this.lstDigics = data;
    //     this.rowData = data;
    //   },
    //   error => {
    //     let msg
    //     if(error.error.message){
    //       msg = error.error.message;
    //     }else if(error.message){
    //       msg =error.message;
    //     }else{
    //       console.log("erreur :");
    //       console.log(JSON.parse(error));
    //       msg = "erreur Non parsable, ouvez la console";
    //     }
    //     this.alertService.error(msg);

    //   },
    // ()=>{

    // });
  }
  subscribeformChange() {
    this.formService.formDigicodsSearchChanged.subscribe(
      (param) => {

        this.lstDigics = new MatTableDataSource(param);

      });

  }

  create(){
    //DOTO: implement (new)
  }
  gerer(installation){
    this.router.navigate(['/manageDigicod'],{queryParams: {id:installation.id}});
  }
  journal(installation){
    this.router.navigate(['/logsDigicod'],{queryParams: {id:installation.id}});
  }
  openNotifications(installation){
    this.router.navigate(['/notifsDigicod'],{queryParams: {id:installation.id}});
  }
  createInstallation(){
    this.router.navigateByUrl('/createDigicod');
  }
  forceOpen(installation){

    this.installationService.forceOpen(installation.id).subscribe(
      data =>{

        this.alertService.success("Commande d'ouverture "+ installation.label + " envoyée");
      },
      error=>{
        this.alertService.error(error);
      },
      ()=>{
        //this.usubscribe();
      }
    );
  }
  delete(installation){
    this.installationService.delete(installation.id);
    // .subscribe(
    //   () => this.onDeleteSuccess(),
    //   () => this.onDeleteError()
    // );

  }


  onChange() {
    // this.formService.raiseReloadDigicodsSearchRequestEvent();
  }
}
