import { Component, OnInit } from '@angular/core';
import { Alert, AlertType } from '../modal/alertType';
import { AlertService } from '../services/alert-service.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  message: any;
  JSON: JSON;
  alerts: Alert[] = [];
   constructor(private alertService: AlertService) {
     this.JSON = JSON;
    }
   ngOnInit(){
     this.alertService.getAlert().subscribe((alert: Alert) => {
       if (!alert) {
           // filter out alerts without 'keepAfterRouteChange' flag
           this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

           // remove 'keepAfterRouteChange' flag on the rest
           this.alerts.forEach(x => delete x.keepAfterRouteChange);
           return;
       }

       // add alert to array
       this.alerts.push(alert);
   });
 }

 removeAlert(alert: Alert) {
   this.alerts = this.alerts.filter(x => x !== alert);
 }

 cssClass(alert: Alert) {
   if (!alert) {
       return;
   }

   // return css class based on alert type
   switch (alert.type) {
       case AlertType.Success:
           return 'alert alert-success';
       case AlertType.Error:
           return 'alert alert-danger';
       case AlertType.Info:
           return 'alert alert-info';
       case AlertType.Warning:
           return 'alert alert-warning';
   }
 }
}
