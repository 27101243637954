import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { Role } from '../class/role';
import { UsersService } from '../services/users.service';
import { Observable } from 'rxjs';
import { FormChangeService } from '../services/form-change.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  aps : [];
  links;
  userLogged;
  constructor(
    public authenService: AuthenticationService,
    private userSev: UsersService,
    public router: Router,
    private formServ: FormChangeService,
    ) {
      this.links = new Array();
}

  ngOnInit() {
    this.formServ.reloadLoginStatusEvent.subscribe(
      (data) =>{
        this.userLogged = this.authenService.isAuthenticated;
        // this.updateNavAfterAuth();
      },
      (error)=>{

      }
    );
    this.formServ.userAps.subscribe(
      data =>{
        this.aps = data;
      }
    )
  }
  goToProspects(app_name, app_id){

    this.router.navigate(['prospects',app_name], { state: { productId: app_id }});
  }
  userLoggedIn() {
    return this.userLogged();
  }
  logout() {
    this.authenService.logout();
    this.updateLoginStatus(false);
  }
  updateLoginStatus(status: boolean) {
      if (!status) {
        this.clearAllItems();
      }
    }
    get currentUser() {
      let tmp = this.authenService.getUserLoggedIn();
      return tmp;
  }
  changePass(){
     this.router.navigate(['/loginChange']);
  }

  profil(){
     this.router.navigate(['/profil']);
  }

  params(){
     this.router.navigate(['/params']);
  }


getLinks() {
  return this.links;
}

  updateNavAfterAuth(): void {

  }
  addItem({ text, path }) {
    this.links.push({ text: text, path: path });
  }

  removeItem({ text }) {
    this.links.forEach((link, index) => {
      if (link.text === text) {
        this.links.splice(index, 1);
      }
    });
  }

  clearAllItems() {
    this.links.length = 0;
  }

}
