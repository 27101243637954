import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { AlertService } from "./services/alert-service.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "../app/modal/modal.component";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from './services/authentication.service';
import { FormChangeService } from './services/form-change.service';
import { Router } from '@angular/router';
import { ProspectsComponent } from './prospects/prospects.component';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "devarchi-paie & personnel";
  messages = new Array<any>();
  subscriptions = new Array<Subscription>();
  constructor(
    private alertService: AlertService,
    private modalService: NgbModal,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    private router: Router,
  ) {

    // this.router.config.unshift(


    // );
  }
  ngAfterViewInit(): void {

  }
  ngOnInit() {
  }

}
