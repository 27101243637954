import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormChangeService } from '../services/form-change.service';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert-service.service';
import { ProspectService } from '../services/prospect.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { prospectStatus, soncas, DevArchiProducts } from '../class/unit.enum';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { ProductService } from '../services/product.service';
import { ProductUpdateComponent } from '../product-update/product-update.component';
import { ProductCreateComponent } from '../product-create/product-create.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent  implements OnInit, OnDestroy {
    constructor(private formService: FormChangeService,
      private authenticationService: AuthenticationService,
      private alertService: AlertService,
      private productServ: ProductService,
      private modalService: NgbModal,
      private router: Router,
      private route: ActivatedRoute,
      private activeRouter:ActivatedRoute
    ) {

    }
    ngOnDestroy(): void {
    }
    searchText;
    lstProducts = new MatTableDataSource(new Array());
    displayedColumns = ['name','desc','gratification','Actions'];

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    keys = Object.keys;
  ProductsEmun = DevArchiProducts;
    getKeys(object): string[] {
      return Object.keys(object);
    }

    ngOnInit() {
      this.lstProducts.sort = this.sort;
      this.subscribeformChange();
    }
    subscribeformChange() {

      this.formService.formProductSearchChanged.subscribe(
        (param) => {
          debugger;
          this.lstProducts = new MatTableDataSource(param);
        },
        (error) =>{
          console.log(error);
        });

    }

    create(): void {
      const modalRef = this.modalService.open(ProductCreateComponent, { backdrop: 'static', keyboard: false });
      modalRef.result.then(result => {
        if (result) {
          debugger;
          this.createProduct(result);
        }
      });
    }
    edit(product) {
      const modalRef = this.modalService.open(ProductUpdateComponent, { backdrop: 'static', keyboard: false });
      modalRef.componentInstance.product = product;
      modalRef.result.then(result => {
        if (result) {
          this.updateProduct(result);
        }
      });
    }
    openDeleteConfirm(id): void {
      const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
      modalRef.componentInstance.title = "Suppresion d'un produit";
      modalRef.componentInstance.message = "Confirmer la suppression ?";
      modalRef.componentInstance.withCancel = true;
      modalRef.result.then(result => {
        if (result == true) {
          this.delete(id);
        }
      });
    }
    delete(id) {
      this.productServ.deleteProduct(id).subscribe(
        () => this.onDeleteSuccess(),
        () => this.onDeleteError()
      );

    }
    createProduct(product){
      this.productServ.saveProducts(product).subscribe(
        (data) => {
          this.alertService.success('Produit créé !', true);
          this.authenticationService.getUserDevArchiProducts().subscribe(
            (data)=>{
              debugger;
              let lstApp = data as Array<any>;
              let userApps = lstApp.map(el=>{
                return { text: el['name'], path: el['name'], appId: el['id'] };
              });
              localStorage.setItem("userApps", JSON.stringify(userApps));
              this.formService.raiseReloadProspectSearchRequestEvent();
            },
            (error) =>{
              debugger;
            });
        },
        (error) => {
          this.alertService.error('Erreur lors de la création de produit')
        }
      )
    }

    updateProduct(product){
      this.productServ.putProduct(product).subscribe(
        (data) => {
          debugger;
          this.alertService.success('Produit mis à jour !', true);
          this.authenticationService.getUserDevArchiProducts().subscribe(
            (data)=>{

              let lstApp = data as Array<any>;
              let userApps = lstApp.map(el=>{
                return { text: el['name'], path: el['name'], appId: el['id'] };
              });
              localStorage.setItem("userApps", JSON.stringify(userApps));
              this.formService.raiseReloadProspectSearchRequestEvent();

            },
            (error) =>{

            });
        },
        (error) => {
          this.alertService.error('Erreur lors de la mise à jour Produit')
        }
      )
    }
    protected onDeleteSuccess(): void {
      this.alertService.success('Suppression effectuée.', false);
      this.formService.raiseReloadProspectSearchRequestEvent();
    }


    protected onDeleteError(): void {
      this.alertService.warn('Erreur de suppression.', false);
    }

    onChange() {
      this.formService.raiseReloadProspectSearchRequestEvent();
    }

}
