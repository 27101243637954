import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TenantService } from '../services/tenant.service';
import { Share } from '../class/unit.enum';

@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.scss']
})
export class ProductUpdateComponent implements OnInit {

  @Input() product: any;
  model: any = {};
  form;
  tenants;
  keys = Object.keys;
  shareEnum = Share;
  constructor (
    public alertService: AlertService,
    private modalService: NgbModal,
    public authenSer: AuthenticationService,
    private tenantSer: TenantService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
  ) { }

  validate() {
      this.activeModal.close(this.form);
  }
  ngOnInit() {
    if(this.authenSer.isSupperAdmin){
      this.tenantSer.getTenants().subscribe(
        (data) => {
          this.tenants = data;
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    }
    this.updateForm(this.product);
  }

  updateForm(absence: any) {
    debugger;
        this.form =
            this.fb.group({
              name: new FormControl(absence.name),
              gratification: new FormControl(absence.gratification),
              desc: new FormControl(absence.desc),
              isPublic: new FormControl(absence.isPublic),
              menu: new FormControl(absence.menu),
              share: new FormControl(absence.share),
              tenantId: new FormControl(absence.tenantId),
            });
  }
  private createFromForm(): any {
    let tmp = {
      name: this.form.get(["name"]).value,
      gratification: this.form.get(["gratification"]).value,
      desc:this.form.get(["desc"]).value,
      isPublic: this.form.get(["isPublic"]).value,
      menu: this.form.get(["menu"]).value ,
      share: this.form.get(["share"]).value,
      tenantId: this.form.get(["tenantId"]).value,
      id: this.product.id,
    };
    return tmp;
  }
  update() {
    debugger;
        this.product["name"] =this.form.controls.name ? this.form.controls.name : '';
        this.product["gratification"] =this.form.controls.gratification ? this.form.controls.gratification : '';
        this.product["desc"] =this.form.controls.desc ? this.form.controls.desc : '';
        this.product["isPublic"] =this.form.controls.isPublic ? this.form.controls.isPublic : '';
        this.product["menu"] =this.form.controls.menu ? this.form.controls.menu : '';
        this.product["share"] =this.form.controls.share ? this.form.controls.share : '';
        this.product["tenantId"] =this.form.controls.tenantId ? this.form.controls.tenantId : '';
        this.close(this.createFromForm());
  }
  close(data) {
    this.activeModal.close(data);
  }

}
