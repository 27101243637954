import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { ProspectService } from 'src/app/services/prospect.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { Router } from '@angular/router';
import { NgModel } from '@angular/forms';
import { RangesFooter } from './ranges-footer/ranges-footer.component';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import * as moment from 'moment';
import { prospectStatus } from 'src/app/class/unit.enum';

@Component({
  selector: 'app-form-min-prospect',
  templateUrl: './form-min-prospect.component.html',
  styleUrls: ['./form-min-prospect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormMinProspectComponent implements OnInit,OnChanges {
  rangesFooter = RangesFooter;
  dateRange: SatDatepickerRangeValue<Date>;
  sites: {};
  selectedCustomer: string;
  prospects;
  searchStatus: any[];
  @Input() productId: any;
  selectAll: boolean;
  searchText: string;
  keys = Object.keys;
  statusEnum = prospectStatus;
  // searchNotes: string;
  newSearched = true;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  @Input() withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private prospectSer: ProspectService,
    private formService: FormChangeService,
    public router: Router) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error("Method not implemented.");

    this.rechercher();
  }


  ngOnInit() {

    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadProspectRequest.subscribe(
      () => {
        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let stausFilter = this.getStatusFilter();
    let prospectFiltred = prospects;
    if (filter) {
      let text = filter.split(' ');
      prospectFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = (item['companyName'] && item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['contactTel'] && item['contactTel'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['contactMail'] && item['contactMail'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || item['note'] && item['note'].toUpperCase().indexOf(el.toUpperCase()) > -1
            return tmp;
        });

        return flt;
      });

    }

    if(stausFilter && stausFilter.length > 0){
      debugger
      prospectFiltred = prospectFiltred.filter(function (item) {
        let flt = stausFilter.every(function (el) {
          let tmp = (item['status'] && stausFilter.indexOf(item['status'].toUpperCase()) > -1);
            return tmp;
        });
        return flt;
      });
    }
    prospectFiltred = this.filterPrspectDateRange(prospectFiltred);
    return prospectFiltred;
  }
  filterPrspectDateRange(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    if(this.dateRange){
      let start = moment(this.dateRange.begin).startOf('day');
      let end = moment(this.dateRange.end).startOf('day');
      tmp = tmp.filter(element => {
        let nextContact = element.nextContact?moment(element.nextContact): null;
        return nextContact && nextContact.startOf('day').isBetween(start,end,undefined,'[]');
      });

    }
    return tmp;
  }

  private getTextFilter() {
    return this.searchText? this.searchText.trim(): null;
  }

  private getStatusFilter(){
    return this.searchStatus;
  }
rechercher() {

    this.getProspectsFromBackEnd(this.productId)
      .subscribe(
        (data) => {
          let tmpUsers = data as Array<any>;
           tmpUsers = this.findTextInProspect(tmpUsers);
          this.formService.raiseProspectListEvent(tmpUsers);
        }
        , (error) => {
          console.log(error);
          this.formService.raiseProspectListEvent([]);
        });
  }

  onFormChange() {
    this.rechercher();
  }

  getProspectsFromBackEnd(app) {

    return this.prospectSer.getProspects(app);
  }

}
