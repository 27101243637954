import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AccessMode, PayeSystem } from 'src/app/class/unit.enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from 'src/app/services/form-change.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TenantService } from 'src/app/services/tenant.service';
import { ModalRASComponent } from 'src/app/modal/modalRAS.component';
import { AlertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-update-tentant',
  templateUrl: './update-tentant.component.html',
  styleUrls: ['./update-tentant.component.scss']
})
export class UpdateTentantComponent implements OnInit {

  tenantUpdateForm: FormGroup;
  tenant: any;
  connectionTestOk;
  testOngoing = false;
  testStated = false;
  testBtnDisable = false;
  PayeSystemEnum = PayeSystem;

  keys = Object.keys;
  tenantUpdate: any;

  accessModeEnum = AccessMode;
  constructor(protected tenantServ: TenantService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    protected formService: FormChangeService,
    private route: Router,
    protected modalService: NgbModal,) {

      this.tenant = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.tenant:null;
    }

  ngOnInit(): void {
    this.initForm();
    if(this.tenant){

      this.updateForm(this.tenant);
    }

  }

  initForm() {
    this.tenantUpdateForm = this.fb.group({
      id: new FormControl(""),
      activity: new FormControl(""),
      adress: new FormControl(""),
      city: new FormControl(""),
      companyName: new FormControl(""),
      contactEmail: new FormControl(""),
      contactName: new FormControl(""),
      contactPosition: new FormControl(""),
      cp: new FormControl(""),
      methodPaymentId: new FormControl(""),
      siret: new FormControl(""),
      stripeCustomerId: new FormControl(""),
      tel1: new FormControl(""),
      tel2: new FormControl(""),
    });
  }

  updateForm(tenant): void {
    this.tenantUpdateForm.patchValue({
      id: this.tenant.id ,
      activity: tenant.activity,
      adress: tenant.adress,
      city: tenant.city,
      companyName: tenant.companyName,
      contactEmail: tenant.contactEmail,
      contactName: tenant.contactName,
      contactPosition: tenant.contactPosition,
      cp: tenant.cp,
      methodPaymentId: tenant.methodPaymentId,
      siret: tenant.siret,
      stripeCustomerId: tenant.stripeCustomerId,
      tel1: tenant.tel1,
      tel2: tenant.tel2,
    });
  }

  update() {
    let tenant = this.createFromForm();
    this.tenantServ.putTenant(tenant).subscribe(
      (data) => {
        this.alertService.success("Modification effectuée.", true);
          this.route.navigateByUrl('/tenants');
      },
      (error) => {
        this.alertService.warn("Erreur de modification.", true);
      }
    );
  }
  isValid(f) {

  }

  private createFromForm(): any {
    return {
      companyName : this.tenantUpdateForm.get(['companyName'])!.value,
      siret : this.tenantUpdateForm.get(['siret'])!.value,
      adress : this.tenantUpdateForm.get(['adress'])!.value,
      cp : this.tenantUpdateForm.get(['cp'])!.value,
      city : this.tenantUpdateForm.get(['city'])!.value,
      contactName : this.tenantUpdateForm.get(['contactName'])!.value,
      contactPosition : this.tenantUpdateForm.get(['contactPosition'])!.value,
      contactEmail : this.tenantUpdateForm.get(['contactEmail'])!.value,
      tel1 : this.tenantUpdateForm.get(['tel1'])!.value,
      tel2 : this.tenantUpdateForm.get(['tel2'])!.value,
      activity : this.tenantUpdateForm.get(['activity'])!.value,
      stripeCustomerId : this.tenantUpdateForm.get(['stripeCustomerId'])!.value,
      methodPaymentId : this.tenantUpdateForm.get(['methodPaymentId'])!.value,
      id: this.tenant.id
    };
  }

}
