import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { NgModel } from '@angular/forms';
import { AlertService } from '../services/alert-service.service';
import { ProspectService } from '../services/prospect.service';
import { FormChangeService } from '../services/form-change.service';
import { Router } from '@angular/router';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-form-min-products',
  templateUrl: './form-min-products.component.html',
  styleUrls: ['./form-min-products.component.scss']
})
export class FormMinProductsComponent implements OnInit {
  selectedTenant: string;
  searchText: string;
  newSearched = true;
  model: NgModel;
  constructor(
    private alertService: AlertService,
    private productService: ProductService,
    private formService: FormChangeService,
    public router: Router) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.rechercher();
  }


  ngOnInit() {

    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadProductRequest.subscribe(
      () => {
        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let prospectFiltred = prospects;
    if (filter) {
      let text = filter.split(' ');
      prospectFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = (item['name'] && item['name'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            return tmp;
        });

        return flt;
      });

    }

    return prospectFiltred;
  }

  private getTextFilter() {
    return this.searchText? this.searchText.trim(): null;
  }

rechercher() {
    debugger;
    this.getProductsFromBackEnd()
      .subscribe(
        (data) => {
          let tmpUsers = data as Array<any>;
           tmpUsers = this.findTextInProspect(tmpUsers);
          this.formService.raiseProductListEvent(tmpUsers);
        }
        , (error) => {
          console.log(error);
          this.formService.raiseProductListEvent([]);
        });
  }

  onFormChange() {
    this.rechercher();
  }

  getProductsFromBackEnd() {
    return this.productService.getProducts();
  }
}
