export enum unit {
    heure,
    km,
    '€',
    nb,
    jour
}
export enum unitType {
    heure,
    jour
}

export enum roleEnum {
    ROLE_USER,
    ROLE_ADMIN,
    ROLE_SUPER_ADMIN
}
export enum AccessMode {
  EDITION = 'EDITION',
  CONSULTATION = 'CONSULTATION'
}
export enum Civilitee {
  Madame = 'MADAME',
  Mademoiselle = 'MADEMOISELLE',
  Monsieur = 'MONSIEUR',
}
export enum PayeSystem {
  SAGE = 'Sage',
  SILAE = 'Silae',
  QUADRA = 'Quadra',
  ADP = 'Adp',
  ISAPAYE = 'Isapaye',
  DIA = 'Dia'
}
export enum SoldeCompteReason {
  DEMISSION = 'Démission',
  LICENCIEMENT = 'Licenciement',
  RUPTURE_CONV = 'Rupture conventionnelle',
  FIN = 'Fin de CDD',
  DEPART_RETRAITE = 'Départ à la retraite',
  MISE_RETRAITE = 'Mise à la retraite',
  DECES = 'Décès',
}
export enum LicenciementType {
  ECO= 'Economique',
  FAUTE = 'Faute',
  PERSO = 'Personnelle',
  INAPTITUDE_PRO = 'Inaptitude Pro',
  INAPTITUDE_NON_PRO = 'Inaptitude non Pro',
  CAUSE_REEL_SERIEUSE = 'Cause réelle et sérieuse',
  INSUFFISANCE = 'Insuffisance',
}
export enum FaultType {
  SIMPLE= 'simple',
  GRAVE= 'grave',
  LOURDE= 'lourde'
}
export enum soncas{
  SECURITE = 'Sécurité',
  ORGUEIL = 'Orgueil',
  NOUVEAUTE = 'Nouveauté',
  CONFORT = 'Confort',
  ARGENT = 'Argent',
  SYMPATHIE = 'Sympathie'
}
export enum prospectStatus{
  TO_PROSPECT = 'A prospecter',
  TO_RECONTACT = 'A recontacter',
  MEET_PLANIFIED = 'RDV pris',
  WAITING_FEEDBACK = 'Attente de retour',
  KO = 'Sans suite',
  SIGNING = 'OK'
}
export enum typeContrat {
  CDI= 'CDI',
  CDD= 'CDD',
  APPRENTISSAGE= 'Apprentissage',
  PROFESSIONNALISATION= 'Professionnalisation'
}
export enum DevArchiProducts {
  PAIE_PUSH= 'Paie Push',
  DOMOTIC= 'Domotic',
  DIGIC_ON_DEMAND= 'Digic On Demand',
  CONSULTING = 'Consulting'
}
export enum Share {
  NONE = 'Non',
  PARTIAL = 'partiel',
  FULL = 'total'
}
