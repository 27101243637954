import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICustomer, Customer } from 'src/app/class/customer';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { User, IUser } from 'src/app/class/user';
import { roleEnum } from 'src/app/class/unit.enum';
import { Role } from 'src/app/class/role';
import { UsersService } from 'src/app/services/users.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  roles = new Array();
  products;
  selectedRoles = new Array();
  selectedProducts;
  selectedTenant: any[];
  tenants: Object;
  constructor(
    protected userService: UsersService,
    private productService: ProductService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

      Object.keys(Role).forEach(rl =>{
        this.roles.push(Role[rl]);
      });

     }

  ngOnInit(): void {
    this.getRoles();
    this.getProducts();
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      // customerId: [],
      //tenantId: [],
      firstname: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      login: ['', [Validators.required]],
    });

  }
  // convenience getter for easy access to form fields
  get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    return {
      // customerId: this.userCreateForm.get(['customerId'])!.value,
      //tenantId: this.authenSer.isSupperAdmin?this.tenantId:null,
      login: this.userCreateForm.get(['login'])!.value,
      email: this.userCreateForm.get(['email'])!.value,
      firstname: this.userCreateForm.get(['firstname'])!.value,
      name: this.userCreateForm.get(['name'])!.value,
      roles: this.selectedRoles,
      productIds: this.selectedProducts,
    };
  }


  save(): void {
    this.submitted = true;
    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.userService.saveUser(this.userForm));
    }
  }

  getRoles() {
    if(this.authenSer.isSupperAdmin){
      this.userService.getRoles().subscribe(
        (data) =>{
          this.roles = data as Array<any>;
        },
        (error)=>{
          console.log(error);
        });
    }
  }
  getProducts() {
    this.productService.getProducts().subscribe(
      (data) => {
        let tmp = data as Array<any>;
        this.products = data;
      },
      (error) => {
        this.alertService.error(error);
      }
    )
  }
  getCustomersFromBackEnd(filter) {
    // return this.customerService.getCustomers();
  }

  getTenantsFromBackEnd() {
    // return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<ICustomer>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Utilisateur sauvegardé !', true);
    this.formService.raisereloadSearchRequestUserEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', true);
  }
  private getFilter() {

    let filter = this.searchText ? this.searchText : null;
    return filter;
  }
  private findTextInCustomers(customers, text) {
    text = text?text.split(' '):'';
    return customers.filter(function (item) {

      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }
}
