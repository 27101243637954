import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const apiUrl = environment.serverUrl + 'api/';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }

  saveProducts(prospect): Observable<any> {

    const url = apiUrl + this.authenSrv.getUserApiTargetUrl() +'/products';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(url, prospect, { withCredentials: true });
  }

  putProduct(prospect): Observable<any> {
      const url = apiUrl + this.authenSrv.getUserApiTargetUrl() + '/products';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.put(url, prospect, { withCredentials: true });
  }

  deleteProduct(id: any) {
    const url = apiUrl + this.authenSrv.getUserApiTargetUrl() + '/products/' + id;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(url, { withCredentials: true });
  }

  getProducts() {
    let subUrl = this.authenSrv.getUserApiTargetUrl() + '/products';
    // if(appName){
    //   subUrl += '/product/' + appName;
    // }
    const url = apiUrl + subUrl;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { withCredentials: true });
  }
}
