export class Role {
    static  User = new Role('ROLE_USER', 'user');
    static Admin = new Role('ROLE_ADMIN', 'admin');
    static Sale = new Role('ROLE_SALE', 'sale');
    static SupperAdmin = new Role('ROLE_SUPER_ADMIN', 'superadmin');
    private constructor(private readonly key: string, public readonly value: any) {
    }
    public static valueOf(str: string ){
      if(str === 'ROLE_USER'){
        return Role.User;
      }
      if(str === 'ROLE_ADMIN'){
        return Role.Admin;
      }
      if(str === 'ROLE_SALE'){
        return Role.Sale;
      }
      if(str === 'ROLE_SUPPERADMIN'){
        return Role.SupperAdmin;
      }
    }
    toString() {
      return this.key;
    }
}
