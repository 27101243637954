import { Component, OnInit, Input } from '@angular/core';
import { ProspectService } from 'src/app/services/prospect.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { Router } from '@angular/router';
import { NgModel } from '@angular/forms';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import * as moment from 'moment';
import { InstallationService } from 'src/app/services/installation.service';
import { RangesFooter } from 'src/app/prospects/form-min-prospect/ranges-footer/ranges-footer.component';

@Component({
  selector: 'app-form-min-digicods',
  templateUrl: './form-min-digicods.component.html',
  styleUrls: ['./form-min-digicods.component.scss']
})
export class FormMinDigicodsComponent implements OnInit {

  rangesFooter = RangesFooter;
  dateRange: SatDatepickerRangeValue<Date>;
  sites: {};
  selectedCustomer: string;
  prospects;
  selectedSite: any[];

  selectAll: boolean;
  searchText: string;
  searchTextCust: string;
  newSearched = true;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  @Input() withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private installationService: InstallationService,
    private formService: FormChangeService,
    public router: Router) {
  }


  ngOnInit() {
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadDigicodsRequest.subscribe(
      () => {

        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let resultFiltred = prospects;
    if (filter) {
      let text = filter.split(' ');
      resultFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = (item['label'] && item['label'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['desc'] && item['desc'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['creationBy'] && item['creationBy'].toUpperCase().indexOf(el.toUpperCase()) > -1);
            return tmp;
        });

        return flt;
      });

    }
    resultFiltred = this.filterDigicodDateRange(resultFiltred);
    return resultFiltred;
  }
  filterDigicodDateRange(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    if(this.dateRange){
      let start = moment(this.dateRange.begin).startOf('day');
      let end = moment(this.dateRange.end).startOf('day');
      tmp = tmp.filter(element => {
        let dateCreation = element.dateCreation?moment(element.dateCreation): null;
        return dateCreation && dateCreation.startOf('day').isBetween(start,end,undefined,'[]');
      });

    }
    return tmp;
  }

  private getTextFilter() {
    return this.searchText;
  }

rechercher() {
    this.getDigicodsFromBackEnd()
      .subscribe(
        (data) => {
          let tmpRes = data as Array<any>;
           tmpRes = this.findTextInProspect(tmpRes);
          this.formService.raiseDigicodsListEvent(tmpRes);
        }
        , (error) => {
          console.log(error);
          this.formService.raiseDigicodsListEvent([]);
        });
  }

  onFormChange() {
    this.rechercher();
  }

  getDigicodsFromBackEnd() {
    return this.installationService.getInstallationsFromBack();
  }


}
