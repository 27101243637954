import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { TenantService } from '../services/tenant.service';
import { FormChangeService } from '../services/form-change.service';
import { AlertService } from '../services/alert-service.service';
import { Subscription } from 'rxjs';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRASComponent } from '../modal/modalRAS.component';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss']
})
export class TenantsComponent implements OnInit {

  subscriptions = new Array<Subscription>();
  lstTenants = [];
  filter: any;
  columnsHeaderToDisplay = ["Nom de l'entreprise",'Siret','Telephone','Email','Actions'
  ];
  overlayRef: OverlayRef | null;

  constructor(public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private alertService: AlertService,
    private formService: FormChangeService,
    private tenantServ: TenantService,
    public router: Router,
    protected modalService: NgbModal) { }

    ngOnDestroy(): void {
      this.subscriptions.forEach(sub =>{
        sub.unsubscribe();
      });
    }
  ngOnInit() {
    this.subscribeformChange();
  }

  subscribeformChange() {
    this.subscriptions.push(
      this.formService.formTenantListChanged.subscribe(
      (param) => {
        this.lstTenants =  Array.isArray(param) ?param: param as Array<any>;
        if(this.lstTenants){
          this.lstTenants.forEach(el =>{
            if (!el.hasOwnProperty('companyName')){
              el['companyName'] = '';
            }
          });
        }
      }));
  }

  deleteTenant(idTenant) {
    this.subscriptions.push(
      this.tenantServ.deleteTenant(idTenant)
      .subscribe(
        (result) => {
          this.alertService.success('Tenant supprimé !', true);
          this.formService.raisereReloadCustomerEvent();
        },
        (result) => {
          this.alertService.error('Erreur', true);
        }
      ));

  }
  editTenant(tenant) {
    this.router.navigateByUrl('/update-tenant', { state: {tenant:tenant}});
  }

  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppression d'un Tenant";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.deleteTenant(id);
      }
    });
  }

}
