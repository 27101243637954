import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { HttpResponse } from "@angular/common/http";
import { FormChangeService } from "../../services/form-change.service";
import { AlertService } from "../../services/alert-service.service";
import * as moment from "moment";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../services/authentication.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { ProspectService } from 'src/app/services/prospect.service';
import { soncas } from 'src/app/class/unit.enum';
import { prospectStatus } from 'src/app/class/unit.enum';
import { ProductService } from 'src/app/services/product.service';
@Component({
  selector: 'app-prospect-create',
  templateUrl: './prospect-create.component.html',
  styleUrls: ['./prospect-create.component.scss']
})
export class ProspectCreateComponent implements OnInit, OnDestroy {
  parentRouteId: number;
  private sub: any;

  employeesCreateForm: FormGroup;
  soncasEnum = soncas;
  statusEnum = prospectStatus;
  timerKeepAlive:any;
  keys = Object.keys;
  selectedSite: string = null;
  selectedCustomer: string = null;
  selectedCivilite: string = null;
  prospectToSave: any;
  emailInvalid: boolean;
  typeContrat = [
    { id: 1, code: "TEL", name: "Tel" },
    { id: 2, code: "MAIL", name: "Email" },
  ];
  product;
  productId;
  lstProducts;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected employeeService: ProspectService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    private productService: ProductService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
  ) {

  }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.product = this.route.parent.snapshot.paramMap.get('product');
    this.productId  = history.state.productId;
    this.initForm();

  }

  initForm() {
    this.employeesCreateForm = this.fb.group({
      adress: new FormControl(""),
      companyName: new FormControl(""),
      contactName: new FormControl(""),
      firstContact: '',
      modeFirstContact: '',
      lastContact: '',
      nextContact: '',
      soncas: '',
      status: '',
      modeLastContact: '',
      contactMail: new FormControl("", [Validators.email]),
      contactTel: new FormControl(""),
      note: [],
      product: [this.product]
    });
  }
  firstContactChange(){

    let firstContact = this.employeesCreateForm.get(["firstContact"])!.value;
    if(firstContact){
      this.employeesCreateForm['modeFirstContact'] = new FormControl("");
    } else {
      this.employeesCreateForm['modeFirstContact'] = [''];
    }
  }

  lastContactChange(){
    let firstContact = this.employeesCreateForm.get(["lastContact"])!.value;
    if(firstContact){
      this.employeesCreateForm['modeLastContact'] = new FormControl("");
    } else {
      this.employeesCreateForm['modeLastContact'] = [''];
    }
  }

  private createFromForm(): any {
    let tmp = {
      adress: this.employeesCreateForm.get(["adress"]).value,
      firstContact: this.employeesCreateForm.get(["firstContact"]).value ?moment(this.employeesCreateForm.get(["firstContact"]).value).format('YYYY-MM-DD') : null,
      modeFirstContact:this.employeesCreateForm.get(["modeFirstContact"]).value?this.employeesCreateForm.get(["modeFirstContact"]).value:null,
      lastContact: this.employeesCreateForm.get(["lastContact"]).value ?moment(this.employeesCreateForm.get(["lastContact"]).value).format('YYYY-MM-DD') : null,
      nextContact: this.employeesCreateForm.get(["nextContact"]).value ?moment(this.employeesCreateForm.get(["nextContact"]).value).format('YYYY-MM-DD') : null,
      soncas: this.employeesCreateForm.get(["soncas"]).value ? this.employeesCreateForm.get(["soncas"]).value: null,
      status: this.employeesCreateForm.get(["status"]).value ? this.employeesCreateForm.get(["status"]).value: null,
      modeLastContact: this.employeesCreateForm.get(["modeLastContact"]).value? this.employeesCreateForm.get(["modeLastContact"]).value: null,
      companyName: this.employeesCreateForm.get(["companyName"])!.value,
      contactMail: this.employeesCreateForm.get(["contactMail"])!.value,
      contactTel: this.employeesCreateForm.get(["contactTel"])!.value,
      note: this.employeesCreateForm.get(["note"])!.value,
      contactName: this.employeesCreateForm.get(["contactName"])!.value,
      productId: this.productValue(),

    };


    return tmp;
  }
  productValue() {
      let tmp = this.productId;
      return tmp;
  }
  onChangeCivilite(value): void {
    this.selectedCivilite = value;
  }
  save(): void {

    this.prospectToSave = this.createFromForm();

    this.employeeService
      .saveProspects(this.prospectToSave)
      .subscribe(
        (data) => {
          this.alertService.success("Prospect Sauvegardé !", true);
          this.formService.raiseReloadProspectSearchRequestEvent();

          this.goBack();
        },
        (error) => {
          this.alertService.error("Erreur dans le formulaire !", true);
        }
      );
  }

  goBack(){
    this.router.navigate(['prospects',this.product], { state:{productId: this.productId}});
  }
}
