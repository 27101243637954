import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Observable';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class InstallationService {
   installations = [{id:"1",name:'Accès 1',code:"access1"}
  , {id:"2",name:'Accès 2',code:"access2"},
  {id:"3",name:'Accès 3',code:"access3"}];

  constructor(private http: HttpClient) { }

  getInstallations(){
    return this.installations;
  }
  getInstallationsFromBack(){
    let url=environment.serverUrl+'/api/admin/digicodes';
    return this.http.get<any>(url);
  }
  getInstallationByIdFromBack(id: string){
    let url=environment.serverUrl+'/api/admin/digicodes/'+id;
    return this.http.get<any>(url);
  }

  getInstallationById(id : string){
    return this.installations.find(insta => insta.id === id);
  }
  saveInstallation(digicode){
    let url=environment.serverUrl+'/api/admin/digicodes';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url,digicode,{headers ,withCredentials: true});
  }
  createInstallation(digicode){
    let url=environment.serverUrl+'api/admin/digicodes';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(url,digicode,{headers , withCredentials: true});
  }
  forceOpen(digicode: string){
    let url =environment.serverUrl+'api/admin/control/forceOpen?equipmentId='+digicode;
    return this.http.get<any>(url,{withCredentials: true});
  }
  delete(id: string){
    //To be implemented
    return null;
  }
}
